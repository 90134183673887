import React, { useState, useImperativeHandle, forwardRef } from 'react';
import FormSelectMaterial from "../../../nuup/componentes/Forms/FormSelectMaterial.jsx";

const AvancesFilters = forwardRef((props, ref) => {
  const [fecha, setFecha] = useState(null);
  const [inspector, setInspector] = useState(0);
  const [ubicacion, setUbicacion] = useState(0);

  //To call outside
  useImperativeHandle(ref, () => ({
    setDefaultFilters: setDefaultFiltersHandler,
  }));

  const setDefaultFiltersHandler = () => {
    setFecha(null);
    setInspector(0);
    setUbicacion(0);
  };

  const handleFilters = (key, val, setFilter) => {
    setFilter(val);
    const data =
      props.dataAvancesTotal.length != props.dataAvances.length
        ? props.dataAvances
        : props.dataAvancesTotal;
    // update data filtered
    if (val === 0 || val === "") {
      props.setDataAvances(props.dataAvancesTotal);
      setDefaultFiltersHandler(); // without subfilters
    } else {
      props.setDataAvances(data.filter((d) => d[key] == val));
    }
  };

  const getCatByData = (list) => {
    return props.dataAvances
      .map((d) => {
        return {
          value: d["id_" + list],
          label: d["nombre_" + list],
        };
      })
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.value === item.value)
      )
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  };

  // const getInspectores = async () => {
  //   setBlockingAvances(true);
  //   const response = await Api.search("inspectores", {
  //     filter: [],
  //   });
  //   if (response.status == 200) {
  //     const data = []
  //     response.data.forEach(i => {
  //       data.push({
  //         value: i.id,
  //         label: i.nombre,
  //       });
  //     });
  //   }
  //   setBlockingAvances(false);
  // };

  return (
    <div className="row mt-3 mb-4 pr-2">
      <div className="col-md-3">
        <input
          type="date"
          className="form-control"
          placeholder="DD/MM/YYYY"
          defaultValue={fecha}
          onChange={(e) =>
            handleFilters("inspeccion_fecha", e.target.value, setFecha)
          }
        />
      </div>
      <div className="col-md-4">
        <FormSelectMaterial
          id="inspector"
          error={false}
          label="Inspector"
          value={inspector}
          onChange={(val) => handleFilters("id_inspector", val, setInspector)}
          height={30}
          options={getCatByData("inspector")}
          showAll={true}
          showAllLabel="Todos"
        />
      </div>
      <div className="col-md-5">
        <FormSelectMaterial
          id="ubicacion"
          error={false}
          label="Ubicación"
          value={ubicacion}
          onChange={(val) => handleFilters("id_localidad", val, setUbicacion)}
          height={30}
          options={getCatByData("localidad")}
          showAll={true}
          showAllLabel="Todas"
        />
      </div>
    </div>
  );
});

export default AvancesFilters;
