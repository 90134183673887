
import React from 'react';
import IconCircle from '../icon/icon-circle';
import LabelSimple from '../label/label-simple';
import IconMarker from "../icon/icon-marker";

class MarkerLabel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (

            <>
                <IconMarker
                    color={this.props.color}
                    onClick={() => {
                        this.setState((value) => ({ visible: !value.visible }));
                    }}
                >
                </IconMarker>
                <div style={{marginLeft : - 50, display : (this.props.visible) ? 'block' : 'none' }}>
                <div style={{ padding: 5, backgroundColor: 'white', minWidth: 100}}>
                    <center>
                        {this.props.link !== null
                            ? <a style={{color : 'black'}} href={this.props.link} target='_blank'>{this.props.label}</a>
                            : <>{this.props.label}</>
                        }
                    </center>
                </div>
                </div>

            </>
        );
    }

}

export default MarkerLabel;