import React, { useState, useEffect } from "react";
import env from "../../../environment";
import Api from '../../../api/global.service';
import BlockUi from "react-block-ui";

const ParcelasResumenTotales = (props) => {
  const [data, setData] = useState([]);
  const [blocking, setBlocking] = useState(true);

  useEffect(() => {
    if (props.id_ciclo > 0) { getData(); }
  }, [props.id_ciclo]);

  const getData = () => {
    setBlocking(true);
    Api.search('dashboards/parcelas/totales', {
      filter: [{ column: 'id_ciclo', operator: '=', value: props.id_ciclo }]
    }).then(response => {
      setData(response.data);
      setBlocking(false);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  }

  return (
    <BlockUi
    tag="div"
    message=""
    blocking={blocking}
    renderChildren={true}
  >
    <div className="row card-dashboard" style={{ height: 345 }}>
      <div className="col-xl-12 col-md-12">
        <img src={env.images.icon.map} className="pt-2" />
        <span className="dashboard-total-productores">{data[0]?.parcelas_totales}</span>
        <span className="dashboard-total-productores-text">
          {"Parcelas totales"}
        </span>
      </div>
    </div>
  </BlockUi>
  );
};

export default ParcelasResumenTotales;
