import React, { useState } from 'react';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../api/global.service';
import MarkerInfoWindow from '../../components/map/marker-infowindow.jsx';
import BlockUi from 'react-block-ui';
import GoogleMapReact from 'google-map-react';
import env from '../../environment';
import SelectCiclo from '../../components/select/select-ciclo.jsx';
import CuentasResumenCategorias from '../../components/dashboards/productores/cuentas-resumen-categorias';
import CuentasResumenGeneros from '../../components/dashboards/productores/cuentas-resumen-generos';
import CuentasResumenEdades from '../../components/dashboards/productores/cuentas-resumen-edades';
import CuentasResumenAltasBajas from '../../components/dashboards/productores/cuentas-resumen-altasbajas';
import CuentasResumenTotales from '../../components/dashboards/productores/cuentas-resumen-totales';
import { getUsaSic } from '../../components/content/coop-config.jsx';
import CuentasResumenProductoresDetalle from '../../components/dashboards/productores/cuentas-resumen-productores-detalle.jsx';

const CuentasResumen = () => {
  const [blockingMap, setBlockingMap] = useState(false);
  const [markers, setMarkers] = useState([]);
  // TODO: eliminate useSTate

  const [center, setCenter] = useState({
    lat: 17,
    lng: -92,
  });
  const [zoom, setZoom] = useState(6);

  const [tipoGrafica, setTipoGrafica] = useState('categoria');

  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [idCiclo, setIdCiclo] = useState(0);
  const [usaSic] = useState(getUsaSic());

  const setFilter = value => {
    setIdCiclo(value.target.id);

    setBlockingMap(true);
    Api.search('parcelas', {
      filter: [
        { column: 'centroide_lat', operator: '!=', value: 'null' },
        { column: 'centroide_lng', operator: '!=', value: 'null' },
        { column: 'id_ciclo', operator: '=', value: value.target.id },
      ],
    })
      .then(response => {
        let excluded = ['9', '106', '162', '177', '331', '531', '781', '782', '804'];

        let d = response.data.filter(function(item) {
          return !excluded.includes(item.id);
        });

        if (typeof d[0] !== 'undefined') {
          setCenter({
            lat: d[0].centroide_lat,
            lng: d[0].centroide_lng,
          });
          setZoom(9);
        }

        setMarkers(d);
        setBlockingMap(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('e', e);
        setBlockingMap(false);
      });
  };

  const getTipoGrafica = () => {
    switch (tipoGrafica) {
      case 'categoria':
        return <CuentasResumenCategorias id_ciclo={idCiclo} />;
      case 'genero':
        return <CuentasResumenGeneros id_ciclo={idCiclo} />;
      case 'edad':
        return <CuentasResumenEdades id_ciclo={idCiclo} />;
      default:
        break;
    }
  };

  const _onChildClick = (key, childProps) => {
    setSelectedElement(childProps);
    setActiveMarker(childProps.id);
    setInfoWindowFlag(true);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item active">Productores</li>
        </ol>
        <SelectCiclo
          setFilter={evt => {
            setFilter(evt);
          }}></SelectCiclo>
      </Breadcrumb>

      <div className="row text-center">
        <div className="col-xl-12">
          {/* Producer chart detail */}
          <div className="row">
            <div className="col-md-12">
              <CuentasResumenProductoresDetalle id_ciclo={idCiclo} />
            </div>
            {/* <div className="col-xl-3 col-md-3 pr-0">
              <div className="col-xl-12 col-md-12">
                <CuentasResumenAltasBajas id_ciclo={idCiclo} />
              </div>
            </div> */}
          </div>
          {/* 
          <div className="row">
            <div className="col-xl-3 col-md-3 pr-4">
              <CuentasResumenTotales id_ciclo={idCiclo} />
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="col-xl-12 col-md-12 card-dashboard">
                <div className="row">
                  <div className="col-xl-3 col-md-3">
                    <p
                      style={{
                        fontSize: 20,
                        fontFamily: 'Lato',
                        fontWeight: 500,
                      }}>
                      Productores por:{' '}
                    </p>
                  </div>

                  <div className="col-xl-9 col-md-9">
                    <div className="d-grid gap-2 d-md-block">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: 10,
                          marginRight: 20,
                          background: tipoGrafica == 'categoria' ? '#FFEED9' : '',
                        }}
                        onClick={() => setTipoGrafica('categoria')}>
                        Categoría
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: 10,
                          marginRight: 20,
                          background: tipoGrafica == 'genero' ? '#FFEED9' : '',
                        }}
                        onClick={() => setTipoGrafica('genero')}>
                        Género
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: 10,
                          marginRight: 20,
                          background: tipoGrafica == 'edad' ? '#FFEED9' : '',
                        }}
                        onClick={() => setTipoGrafica('edad')}>
                        Edad
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">{getTipoGrafica()}</div>
                </div>
                
                {usaSic === 1 && (
                <span style={{ color: '#999' }}>
                  {'* Los datos mostrados corresponden solol a productores en el padrón'}
                </span>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-md-3 pr-0">
              <div className="col-xl-12 col-md-12">
                <CuentasResumenAltasBajas id_ciclo={idCiclo} />
              </div>
            </div>
          </div> 
          */}
        </div>
        <div className="col-xl-3"></div>
      </div>
      {usaSic === 1 && (
      <div className="row">
        <div className="col-xl-12">
          <BlockUi tag="div" message="" blocking={blockingMap} renderChildren={true}>
            <Panel>
              <PanelBody className="p-0">
                <div
                  className="row"
                  style={{
                    height: 520,
                    backgroundColor: 'white',
                    borderRadius: '20px 20px 20px 20px',
                    padding: '20px 10px',
                  }}>
                  <div className="col-xl-12 col-md-12">
                    <div style={{ height: '100%', width: '100%' }}>
                      {markers && (
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: env.googleApiKey }}
                          options={map => ({
                            mapTypeId: map.MapTypeId.SATELLITE,
                          })}
                          center={center}
                          zoom={zoom}
                          onChildClick={_onChildClick}
                          onChildMouseEnter={_onChildClick}
                          onChildMouseLeave={() => setSelectedElement(null)}>
                          {markers &&
                            markers.map((element, i) => {
                              return (
                                <MarkerInfoWindow
                                  key={i}
                                  lat={element.centroide_lat}
                                  lng={element.centroide_lng}
                                  color={element.cuenta_categoria_color}
                                  categoria={element.cuenta_categoria}
                                  productor={element.cuenta}
                                  id={element.id}
                                  selectedElement={selectedElement}
                                  showInfoWindow={showInfoWindow}
                                  activeMarker={activeMarker}></MarkerInfoWindow>
                              );
                            })}
                        </GoogleMapReact>
                      )}
                    </div>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </BlockUi>
        </div>
      </div>
      )} 
    </div>
  );
};
export default CuentasResumen;
