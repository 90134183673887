import React,{useEffect} from 'react';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import BlockUi from 'react-block-ui';
import env from '../../environment.js';
import flattenColumnsRecursive from '../../utils/flattenColumns.js';

const Pagination = ({ blocking, columns, data, pagination, fnBackgroundColor, fnColor }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: flattenColumnsRecursive(columns).map(column => {
          if (column.show === false) {
            return column.accessor || column.id;
          }
        }),
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const getBackgroundColor =
    fnBackgroundColor != null
      ? fnBackgroundColor
      : () => {
          return 'rgba(240, 224, 207, 1.0)';
        };

  const getColor =
    fnColor != null
      ? fnColor
      : i => {
          return 'rgb(95, 95, 89)';
        };


    useEffect(() => {
      gotoPage(0);
    },[data]);

  return (
    <>
      <div style={{ paddingBottom: 0 }}>
        <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
          <div className="table-responsive table-sticky" style={{ marginBottom: 20 }}>
            <table className="table" {...getTableProps()} style={{ borderBottom: '1px solid #e4e7ea' }}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, j) => (
                      <th
                        key={j}
                        className="width-150 text-nowrap"
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          backgroundColor: getBackgroundColor(i, j),
                          color: getColor(i),
                          border: '0px solid red',
                        }}>
                        <div>{column.filter ? column.render('Filter') : null}</div>
                        <div
                          className="d-flex"
                          style={{
                            minWidth: column.minWidth ? column.minWidth : column.width ? 'auto' : '150px',
                            width: column.width ? column.width : 'auto',
                          }}>
                          <span>{column.render('Header')}</span>
                          <span className="ml-auto">
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down fa-fw f-s-14 text-green"></i>
                                ) : (
                                  <i className="fa fa-sort-up fa-fw f-s-14 text-green"></i>
                                )
                              ) : (
                                <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()} style={{ color: '#171C26' }}>
                      {row.cells.map((cell, j) => {
                        return (
                          <td key={j} className="edit_hover_tr" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {pageOptions.length == 0 && !blocking && <div className="w-100 text-center">Sin registros encontrados</div>}
          </div>
          {pagination && pageOptions.length > 0 && (
            <div>
              <div className="d-flex align-items-center justify-content-center">
                <ul className="pagination mb-0">
                  <li className="page-item d-flex align-items-center px-2"></li>
                </ul>
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      <i className="fa fa-angle-double-left"></i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                      <i className="fa fa-angle-left"></i>
                    </button>
                  </li>
                  <li className="page-item d-flex align-items-center px-2">
                    <div>
                      Página{' '}
                      <strong>
                        {pageIndex + 1} de {pageOptions.length}
                      </strong>
                    </div>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                      <i className="fa fa-angle-right"></i>
                    </button>
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      <i className="fa fa-angle-double-right"></i>
                    </button>
                  </li>
                </ul>
                <div className="ml-3 mr-1">Ir a la página:</div>
                <div className="mx-2" style={{ width: 75 }}>
                  <input
                    className="form-control"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0;
                      gotoPage(page);
                    }}
                    style={{ height: 42 }}
                  />
                </div>
                <div>
                  <select
                    className="form-control"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}
                    style={{ height: 42 }}>
                    {env.pagination.pageSizes.map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </BlockUi>
      </div>
    </>
  );
};

export default Pagination;
