import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import Breadcrumb from "../../components/breadcrumb/breadcrumb.jsx";
import Api from "../../api/global.service";
import BlockUi from "react-block-ui";
import env from "../../environment.js";
import SelectCiclo from "../../components/select/select-ciclo.jsx";
import { Colors } from "../../constants/Colors.js";
import {
  BarChart,
  PieChart,
  Pie,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Text,
  Label,
} from "recharts";
import GoogleMapReact from "google-map-react";
import {
  getUsaSic,
  useCoopConfig,
} from "../../components/content/coop-config.jsx";
import CuentasResumenTotales from "../../components/dashboards/productores/cuentas-resumen-totales.js";
import CuentasResumenCategorias from "../../components/dashboards/productores/cuentas-resumen-categorias.js";
import CuentasResumenGeneros from "../../components/dashboards/productores/cuentas-resumen-generos.js";
import CuentasResumenEdades from "../../components/dashboards/productores/cuentas-resumen-edades.js";
import MarkerInfoWindowParcela from "../../components/map/marker-infowindow-parcela.jsx";
import transformWord from "../../utils/transformWord.js";
import descargaKML from "../../utils/downloadKML.js";
// import FormSelectMaterial from "../../nuup/componentes/Forms/FormSelectMaterial.jsx";
import LinkDownloadDashboard from "../../components/link/link-download-dashboard.jsx";
import AvancesFilters from "../../components/dashboards/home/avances-filters.jsx";

const COLORS_PIE = ["#A46B00", "#E49D2A"];
const COLORS_DON = ["#FFEED9", "#A46B00"];

const InicioResume = () => {
  const coopConfig = useCoopConfig();
  const [etiquetas, setEtiquetas] = useState({});
  const [idCiclo, setIdCiclo] = useState(0);
  const [blockingParcelasGeos, setBlockingParcelasGeos] = useState(true);
  const [blockingMultiBar, setBlockingMultiBar] = useState(true);
  const [blockingMap, setBlockingMap] = useState(false);
  const [blockingAvances, setBlockingAvances] = useState(false);
  const [totalesP, setTotalesP] = useState(0);
  const [totalesPD, setTotalesPD] = useState(0);
  const [totalesPPD, setTotalesPPD] = useState(0);
  const [dataGraficaParcelasGeos, setDataGraficaParcelasGeos] = useState([]);
  const [dataGraficaMunicipios, setDataGraficaMunicipios] = useState([]);
  const [dataGraficaLocalidades, setDataGraficaLocalidades] = useState([]);
  const [isLocalidades, setIsLocalidades] = useState(false);
  const [tipoGrafica, setTipoGrafica] = useState("categoria");
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [dataForKML, setDataForKML] = useState([]);
  const [center, setCenter] = useState({
    lat: 17,
    lng: -92,
  });
  const [zoom, setZoom] = useState(6);
  const [usaSic] = useState(getUsaSic);
  const [dataAvances, setDataAvances] = useState([]);
  const [dataAvancesTotal, setDataAvancesTotal] = useState([]);
  const avances = createRef();
  // const [pagAvances, setPagAvances] = useState({ page: 1 });

  useEffect(() => {
    // coopConfig etiquetas >> dashboards Café, Miel, Cacao, Otro
    getEtiquetas();
    // dashboard Miel || No SIC maps
    if (maps) {
      // Clear all previous polygons in map
      polygons.map((p) => p.setMap(null));
      const tempPolygons = [];
      // Add new polygons in map
      markers.forEach(function(marker) {
        if (marker.poligono !== null) {
          let poligono = JSON.parse(marker.poligono);
          let poligono_data = poligono.coordinates[0].map(function(p, index) {
            return { lat: p[1], lng: p[0] };
          });
          var polygon = new maps.Polygon({
            paths: poligono_data,
            strokeColor: "#ffffff", //marker.cuenta_categoria_color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#ffffff", //marker.cuenta_categoria_color,
            fillOpacity: 0.35,
          });
          polygon.setMap(map);
          tempPolygons.push(polygon);
        }
      });
      setPolygons(tempPolygons);
    }

    // infinite scrolling for avances
    // const avancesContainer = document.getElementsByClassName(
    //   "card-avances-body"
    // )[0];
    // const handleAvancesScroll = async () => {
    //   const { scrollTop, clientHeight, scrollHeight } = avancesContainer;
    //   if (
    //     scrollTop + clientHeight >= scrollHeight - 1 &&
    //     dataAvances.length < pagAvances.total
    //   ) {
    //     await getAvanceSIC(); // load data
    //   }
    // };
    // avancesContainer.addEventListener("scroll", handleAvancesScroll);
    // return () => {
    //   avancesContainer.removeEventListener("scroll", handleAvancesScroll);
    // };
  }, [map, maps, markers, coopConfig.config]);

  const getEtiquetas = async () => {
    setEtiquetas({ parcela: await coopConfig.etiquetaParcelas() });
  };

  const getAvanceMunicipios = (value) => {
    setBlockingMultiBar(true);
    Api.search("dashboard/avances/municipios", {
      filter: [{ column: "id_ciclo", operator: "=", value: value.target.id }],
    })
      .then((response) => {
        setBlockingMultiBar(false);
        let dataResponse = response.data;
        setDataGraficaMunicipios(dataResponse);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log("e", e);
        setBlockingMultiBar(false);
      });
  };

  const getAvanceLocalidades = (id_municipio) => {
    setBlockingMultiBar(true);
    Api.search("dashboard/avances/localidades", {
      filter: [
        { column: "id_ciclo", operator: "=", value: idCiclo },
        { column: "id_municipio", operator: "=", value: id_municipio },
      ],
    })
      .then((response) => {
        setBlockingMultiBar(false);
        let dataResponse = response.data;
        setDataGraficaLocalidades(dataResponse);
        setIsLocalidades(true);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log("e", e);
        setBlockingMultiBar(false);
      });
  };

  const getKmlData = (ciclo) => {
    Api.search("parcelas_kml", {
      filter: [{ column: "id_ciclo", operator: "=", value: ciclo }],
    })
      .then((response) => {
        setDataForKML(response.data);
      })
      .catch((e) => {
        console.log("e>>>", e);
      });
  };

  const setFilter = (value) => {
    setIdCiclo(value.target.id);
    getAvanceMunicipios(value);
    if (Number(usaSic) !== 1) {
      getTotalesParcelasCuentas(value);
      getKmlData(value.target.id);
    } else {
      getTotalesParcelasGeos(value);
      getAvanceSIC(value);
    }
    // map
    setBlockingMap(true);
    Api.search("parcelas", {
      filter: [
        { column: "centroide_lat", operator: "!=", value: "null" },
        { column: "centroide_lng", operator: "!=", value: "null" },
        { column: "id_ciclo", operator: "=", value: value.target.id },
      ],
    })
      .then((response) => {
        //TODO: exclude in backend
        let excluded = [
          "9",
          "106",
          "162",
          "163",
          "177",
          "297",
          "331",
          "531",
          "631",
          "637",
          "748",
          "781",
          "782",
          "804",
        ];

        let d = response.data.filter(function(item) {
          return !excluded.includes(item.id);
        });

        if (typeof d[0] !== "undefined") {
          setCenter({
            lat: d[0].centroide_lat,
            lng: d[0].centroide_lng,
          });
          setZoom(9);
        }
        setMarkers(d);
        setBlockingMap(false);
      })
      .catch((e) => {
        setBlockingMap(false);
      });
    // clean
    avances.current.setDefaultFilters(); // without persistent filters
  };

  const getAvanceSIC = async (value) => {
    setBlockingAvances(true);

    const response = await Api.search("dashboards/avances/sic", {
      filter: [
        {
          column: "id_ciclo",
          operator: "=",
          value: value?.target ? value.target.id : idCiclo,
        },
      ],
    });

    if (response.status == 200) {
      setDataAvances(response.data);
      setDataAvancesTotal(response.data);
    }

    setBlockingAvances(false);
  };

  const getTotalesParcelasGeos = (value) => {
    setBlockingParcelasGeos(true);
    setDataGraficaParcelasGeos([]);
    setTotalesP(0);
    setTotalesPD(0);
    setTotalesPPD(0);
    Api.search("dashboard/totales/parcelas/geos", {
      filter: [{ column: "id_ciclo", operator: "=", value: value.target.id }],
    })
      .then((response) => {
        setBlockingParcelasGeos(false);
        let dataResponse = response.data;
        if (dataResponse[0].id_cooperativa) {
          setDataGraficaParcelasGeos([
            {
              name: "Pendientes de geolocalizar",
              value: Number(dataResponse[0].geo_porcentaje_pendiente),
            },
            {
              name: "Parcelas geolocalizadas",
              value: Number(dataResponse[0].geo_porcentaje_realizado),
            },
          ]);
          setTotalesP(dataResponse[0].total_parcelas);
          setTotalesPD(dataResponse[0].total_geo);
          setTotalesPPD(dataResponse[0].pendientes_geo);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log("e", e);
        setBlockingParcelasGeos(false);
      });
  };

  const getTotalesParcelasCuentas = async (value) => {
    const resCuentas = await Api.search("cuentas", {
      filter: [
        { column: "id_ciclo", operator: "=", value: value.target.id },
        { column: "confirmado", operator: "=", value: 1 },
      ],
      sort: [{ column: "clave_tx", sort: "asc" }],
    });

    if (resCuentas.data.length > 0) {
      const totalesParcela = resCuentas.data.reduce(
        (acc, c) => acc + parseInt(c.total_parcelas),
        0
      );
      setTotalesP(totalesParcela);
    }
  };

  const valueAccessor = (attribute) => ({ payload }) => {
    let value = payload[attribute];
    if (value > 0) {
      if (!isLocalidades) {
        if (value > 10) {
          return value;
        } else {
          return "";
        }
      } else {
        return value;
      }
    } else {
      return "";
    }
  };

  const handleBarClick = (e) => {
    let currentPayload = e.activePayload[0].payload;
    getAvanceLocalidades(currentPayload.id_municipio);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomizedLabelB = ({ kapi, metric, viewBox }) => {
    return (
      <Text
        x={0}
        y={0}
        dx={-220}
        dy={40}
        textAnchor="start"
        width={400}
        transform="rotate(-90)"
      >
        {"Nº de productores"}
      </Text>
    );
  };

  const getTipoGrafica = () => {
    switch (tipoGrafica) {
      case "categoria":
        return <CuentasResumenCategorias id_ciclo={idCiclo} />;
      case "genero":
        return <CuentasResumenGeneros id_ciclo={idCiclo} />;
      case "edad":
        return <CuentasResumenEdades id_ciclo={idCiclo} />;
      default:
        break;
    }
  };

  // map methods
  const renderMap = (map, maps) => {
    setMap(map);
    setMaps(maps);
  };

  const _onChildClick = (key, childProps) => {
    setSelectedElement(childProps);
    setActiveMarker(childProps.id);
    setInfoWindowFlag(true);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item active">Inicio</li>
        </ol>
        <SelectCiclo
          setFilter={(evt) => {
            setFilter(evt);
          }}
        ></SelectCiclo>
      </Breadcrumb>
      {Number(usaSic) === 1 ? (
        <>
          {/* dashboard for SIC accounts */}
          <div className="row">
            <div className="col-xl-8">
              <div className="row">
                {/* parcelas registradas card */}
                <div className="col-xl-5 pr-4 mb-3">
                  <BlockUi
                    tag="div"
                    message=""
                    blocking={blockingParcelasGeos}
                    renderChildren={true}
                  >
                    {/* Top left box */}
                    <div
                      className="row card-graph mb-3 bg-white"
                      style={{
                        minHeight: 160,
                        borderRadius: "20px",
                      }}
                    >
                      <div className="col-xl-12 col-md-12">
                        <div className="row p-10 mt-2">
                          <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                            <img src={env.images.card.p} className="pt-2" />
                          </div>
                          <div className="col-xl-9 col-md-9 p-1">
                            <h1 className="font-lato-normal-600">
                              <p style={{ fontSize: 52 }}>
                                {totalesP?.toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </p>
                              <p className="font-lato-normal-400 body-1">
                                Parcelas registradas
                              </p>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Bottom left box */}
                    <div
                      className="row card-graph bg-white"
                      style={{
                        minHeight: 160,
                        borderRadius: "20px",
                      }}
                    >
                      <div className="col-xl-12 col-md-12">
                        <div className="row p-10 mt-2">
                          <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                            <img src={env.images.card.pd} className="pt-2" />
                          </div>
                          <div className="col-xl-9 col-md-9 p-1">
                            <h1 className="font-lato-normal-600">
                              <p style={{ fontSize: 52 }}>
                                {totalesPD?.toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </p>
                              <p className="font-lato-normal-400 body-1">
                                Parcelas geolocalizadas
                              </p>
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </BlockUi>
                </div>
                {/* parcelas geolocalizadas card */}
                <div className="col-xl-7 pr-4">
                  <div
                    className="row card-graph bg-white"
                    style={{
                      minHeight: 338,
                      borderRadius: "20px",
                      padding: "20px 10px",
                    }}
                  >
                    <BlockUi
                      tag="div"
                      message=""
                      blocking={blockingParcelasGeos}
                      renderChildren={true}
                    >
                      <div className="col-xl-12 col-md-12">
                        <h6 className="font-lato-normal-500 heading-box mb-4">
                          Avances en parcelas geolocalizadas:
                        </h6>
                        <div className="row card-graph">
                          <div className="col-xl-6 col-md-6 pr-0">
                            <div className="row">
                              <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                                <img src={env.images.card.circulo_BC8268} />
                              </div>
                              <div className="col-xl-9 col-md-9 pr-0">
                                <Link
                                  style={{
                                    fontSize: "2rem",
                                    color: Colors.primary_500,
                                    textDecoration: "underline",
                                  }}
                                  to={{
                                    pathname: "/admin/parcelas/list",
                                    search: "?geoFilter=1",
                                  }}
                                  className="font-lato-normal-600"
                                >
                                  {totalesPD?.toLocaleString("en-US", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                                </Link>
                                <p className="font-lato-normal-400 subtitle-1 body-1">
                                  Parcelas geolocalizadas
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-3 col-md-3 d-flex justify-content-center align-items-center">
                                <img src={env.images.card.circulo_FFEED9} />
                              </div>
                              <div className="col-xl-9 col-md-9 pr-0">
                                <Link
                                  style={{
                                    fontSize: "2rem",
                                    color: Colors.primary_500,
                                    textDecoration: "underline",
                                  }}
                                  to={{
                                    pathname: "/admin/parcelas/list",
                                    search: "?geoFilter=2",
                                  }}
                                  className="font-lato-normal-600"
                                >
                                  {totalesPPD?.toLocaleString("en-US", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                                </Link>
                                <p className="font-lato-normal-400 subtitle-1 body-1">
                                  Pendientes de geolocalizar
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-md-6 p-0">
                            <ResponsiveContainer
                              width="130%"
                              height={"100%"}
                              style={{ marginLeft: -20 }}
                            >
                              <PieChart width={200} height={200}>
                                <Pie
                                  data={dataGraficaParcelasGeos}
                                  cx={100}
                                  cy={125}
                                  innerRadius={50}
                                  outerRadius={80}
                                  fill="#8884D8"
                                  paddingAngle={1}
                                  dataKey="value"
                                >
                                  {dataGraficaParcelasGeos.map(
                                    (entry, index) => (
                                      <React.Fragment key={`cell-${index}`}>
                                        <Cell
                                          fill={
                                            COLORS_DON[
                                              index % COLORS_DON.length
                                            ]
                                          }
                                        />
                                        <Label
                                          style={{ fontSize: 18 }}
                                          value={
                                            parseInt(
                                              dataGraficaParcelasGeos[1]?.value
                                            ) + "%"
                                          }
                                          position="center"
                                        />
                                      </React.Fragment>
                                    )
                                  )}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      </div>
                    </BlockUi>
                  </div>
                </div>
              </div>
              {/* graph levantamiento de no.productores por municipio y comunidad */}
              <div className="row">
                <div className="col-xl-12 pr-3">
                  <Panel className="card-table">
                    {/* header title and controls chart */}
                    <div className="row">
                      <div className="col-xl-12">
                        <h6 className="font-lato-normal-400 heading-box">
                          AVANCES DE LEVANTAMIENTO POR MUNICIPIO Y COMUNIDAD
                        </h6>
                        {!isLocalidades && (
                          <a
                            className="font-lato-normal-400"
                            onClick={() => setIsLocalidades(false)}
                            style={{
                              color: Colors.gray_base,
                              fontSize: 14,
                              textDecoration: "none",
                            }}
                          >
                            {
                              "Selecciona un municipio para ver el avance de sus comunidades"
                            }
                          </a>
                        )}
                        {isLocalidades && (
                          <a
                            onClick={() => setIsLocalidades(false)}
                            style={{ color: Colors.primary_500, fontSize: 14 }}
                            className="float-right btn btn-link"
                          >
                            {"<= Todos los municipios"}
                          </a>
                        )}
                      </div>
                    </div>
                    <BlockUi
                      tag="div"
                      message=""
                      blocking={blockingMultiBar}
                      renderChildren={true}
                    >
                      <ResponsiveContainer width="100%" height={460}>
                        <BarChart
                          data={
                            isLocalidades
                              ? dataGraficaLocalidades
                              : dataGraficaMunicipios
                          }
                          onClick={handleBarClick}
                        >
                          <CartesianGrid
                            horizontal={false}
                            vertical={false}
                            strokeDasharray="3 3"
                          />
                          <XAxis
                            angle={-45}
                            textAnchor="end"
                            height={120}
                            padding={{ top: 80, left: 15, right: 15 }}
                            dataKey={isLocalidades ? "localidad" : "municipio"}
                          />
                          <YAxis tick={false} label={<CustomizedLabelB />} />
                          <Tooltip />
                          <Legend />
                          <Bar
                            legendType={"none"}
                            name="Realizado"
                            radius={5}
                            barSize={150}
                            dataKey="total_levantado"
                            stackId="a"
                            fill={isLocalidades ? "#4A6700" : "#C7AE34"}
                          >
                            <LabelList
                              position={"insideBottom"}
                              style={{
                                textAnchor: "middle",
                                fontSize: "14",
                                fill: Colors.white,
                              }}
                              valueAccessor={valueAccessor("total_levantado")}
                            />
                          </Bar>
                          <Bar
                            legendType={"none"}
                            name="Pendiente"
                            radius={5}
                            barSize={150}
                            dataKey="total_pendiente"
                            stackId="a"
                            fill={
                              isLocalidades
                                ? Colors.black_medium
                                : Colors.black_medium
                            }
                          >
                            <LabelList
                              position={"insideTop"}
                              style={{
                                textAnchor: "middle",
                                fontSize: "14",
                                fill: Colors.black,
                              }}
                              valueAccessor={valueAccessor("total_pendiente")}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: env.googleApiKey,
                          libraries: ["geometry", "elevation"],
                        }}
                        options={(map) => ({
                          mapTypeId: map.MapTypeId.TERRAIN,
                          rotateControl: true,
                        })}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={() => {}}
                      ></GoogleMapReact>
                    </BlockUi>
                  </Panel>
                </div>
              </div>
            </div>
            {/* ultimos registros sic - avance */}
            <div className="col-xl-4">
              <div
                className="row card-dashboard bg-white"
                style={{
                  borderRadius: "20px",
                  padding: "20px 5px",
                }}
              >
                <BlockUi
                  tag="div"
                  message=""
                  blocking={blockingAvances}
                  renderChildren={true}
                >
                  <div className="col-xl-12 col-md-12 pr-0">
                    <h6 className="font-lato-normal-500 heading-box">
                      Últimos registros
                    </h6>
                    {/* search filters with tags - avance */}
                    <AvancesFilters
                      dataAvances={dataAvances}
                      dataAvancesTotal={dataAvancesTotal}
                      setDataAvances={setDataAvances}
                      ref={avances}
                    />

                    {/* records list -avance */}
                    <div className="card-avances-body">
                      {dataAvances.map((avance) => (
                        <>
                          <div className="row" key={avance.id_api_ficha}>
                            <div className="col-md-7">
                              <small className="text-uppercase">
                                {avance.ficha_title}
                              </small>
                              <p className="mb-0 body-0">
                                <Link
                                  to={{
                                    pathname: `/admin/cuentas/perfil/${avance.uuid_productor}`,
                                  }}
                                  className="m-r-2"
                                  style={{ color: Colors.primary_500 }}
                                >
                                  <span className="text-capitalize">
                                    <u>
                                      {avance.nombre_productor.toLowerCase()}
                                    </u>
                                  </span>
                                </Link>{" "}
                                - {avance.nombre_parcela}
                              </p>
                              <p>
                                <em className="text-secondary">
                                  Por {avance.nombre_inspector} el{" "}
                                  {avance.inspeccion_fecha}
                                </em>
                              </p>
                            </div>
                            <div className="col-md-5 p-0">
                              {avance.reporte == "-" ? (
                                <Link
                                  to={{
                                    pathname: `/admin/parcelas/perfil/print/${avance.uuid}`,
                                  }}
                                  className="btn btn-sirio btn-outline-secondary btn-md mt-3 pr-4 pl-4 font-lato-normal-500 link-btn"
                                  style={{
                                    borderRadius: "20px",
                                    color: Colors.primary_700,
                                  }}
                                >
                                  Ver croquis
                                </Link>
                              ) : (
                                <LinkDownloadDashboard
                                  url={`pdf/${avance.reporte}/${avance.uuid}`}
                                  label="Descargar ficha"
                                  icon={env.images.icon.pdf}
                                ></LinkDownloadDashboard>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </BlockUi>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* dashboard for NO SIC accounts */}
          {dataForKML.length > 0 && (
            <div className="row ">
              <div className="col align-self-end"></div>
              <div className="float-xl-right">
                <p>
                  <img src={env.images.icon.kml} />
                  <a
                    onClick={() => descargaKML(dataForKML)}
                    style={{
                      padding: 10,
                      color: "#789D16",
                      cursor: "pointer",
                    }}
                  >
                    {"Descargar Mapa KML"}
                  </a>
                </p>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xl-3 col-md-3 pr-4">
              <CuentasResumenTotales id_ciclo={idCiclo} usaSic={usaSic} />
            </div>
            <div className="col-xl-6 col-md-6">
              <div className="col-xl-12 col-md-12 card-dashboard">
                <div className="row">
                  <div className="col-xl-4 col-md-4">
                    <p className="dashboard-total-productores-text">
                      Productores por:
                    </p>
                  </div>
                  <div className="col-xl-8 col-md-8">
                    <div className="d-grid gap-2 d-md-block pull-right">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: 10,
                          marginRight: 20,
                          background:
                            tipoGrafica == "categoria" ? "#FFEED9" : "",
                        }}
                        onClick={() => setTipoGrafica("categoria")}
                      >
                        Categoría
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: 10,
                          marginRight: 20,
                          background: tipoGrafica == 'genero' ? '#FFEED9' : '',
                        }}
                        onClick={() => setTipoGrafica('genero')}>
                        Género
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{
                          borderRadius: 10,
                          marginRight: 20,
                          background: tipoGrafica == 'edad' ? '#FFEED9' : '',
                        }}
                        onClick={() => setTipoGrafica('edad')}>
                        Edad
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-md-12">{getTipoGrafica()}</div>
                </div>
                {usaSic === 1 && (
                  <span style={{ color: "#999" }}>
                    * Los datos mostrados corresponden solol a productores en el
                    padrón
                  </span>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-md-3 pr-0">
              <div
                className="row card-category-dashboard"
                style={{ height: 340 }}
              >
                <div className="col-xl-12 col-md-12">
                  <span className="dashboard-total-parcelas-has">
                    {parseInt(totalesP)}
                  </span>
                  <span className="dashboard-titulo-parcelas-has">
                    {transformWord(etiquetas?.parcela, [
                      "plural",
                      "capitalize",
                    ])}{" "}
                    totales
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* map */}
          <div className="row">
            <div className="col-xl-12">
              <BlockUi
                tag="div"
                message=""
                blocking={blockingMap}
                renderChildren={true}
              >
                <Panel>
                  <PanelBody className="p-0">
                    <div
                      className="row"
                      style={{
                        height: 520,
                        backgroundColor: "white",
                        borderRadius: "20px 20px 20px 20px",
                        padding: "20px 10px",
                      }}
                    >
                      <div className="col-xl-12 col-md-12">
                        <div style={{ height: "100%", width: "100%" }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{ key: env.googleApiKey }}
                            options={(map) => ({
                              mapTypeId: map.MapTypeId.SATELLITE,
                            })}
                            center={center}
                            zoom={zoom}
                            onChildClick={_onChildClick}
                            onChildMouseEnter={_onChildClick}
                            onChildMouseLeave={() => setSelectedElement(null)}
                            onGoogleApiLoaded={({ map, maps }) =>
                              renderMap(map, maps)
                            }
                          >
                            {markers &&
                              markers.map((element, i) => {
                                return (
                                  <MarkerInfoWindowParcela
                                    key={i}
                                    lat={element.centroide_lat}
                                    lng={element.centroide_lng}
                                    color={element.cuenta_categoria_color}
                                    productor={element.cuenta}
                                    parcela={element.nombre}
                                    superficie={element.superficie}
                                    id={element.id}
                                    selectedElement={selectedElement}
                                    showInfoWindow={showInfoWindow}
                                    activeMarker={activeMarker}
                                  ></MarkerInfoWindowParcela>
                                );
                              })}
                          </GoogleMapReact>
                        </div>
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </BlockUi>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InicioResume;
